@import '../abstracts/mixins';
@import '../abstracts/variables';

.accountDialog {
  .accountDialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &.signupDialog,
  &.loginDialog {
    .accountLinksContainer.noMobile {
      position: absolute;
      bottom: 16px;
      .accountLink {
        color: white;
        margin: 8px auto;
        font-size: 14px;
        &.forgotLink {
          margin-bottom: 12px;
        }
        .accountLinkButton {
          color: white;
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
          text-transform: initial;
          margin-top: -3px;
          &.paddingLeft {
            padding-left: 6px;
          }
        }
      }
    }
  }
  .accountDialogInner {
    padding: 0;
    flex: 1;
    .accountDialogHeader {
      flex: 0.45;
      background-image: url('/static/img/home/dialog_wave.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include media(tablet-small) {
        background-image: url('/static/img/home/dialog_wave_sm.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      @include media(mobile) {
        background-image: url('/static/img/home/dialog_wave_sm.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        flex: auto;
      }
      .accountDialogHeaderText {
        max-width: 290px;
        h2 {
          font-size: 30px;
          font-weight: 900;
          color: white;
          margin: 10px 0;
        }
        p {
          color: white;
          margin: 0;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
    .accountDialogFormContainer {
      flex: 0.55;
      padding: 20px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media(mobile) {
        flex: auto;
      }
      .forgotDialogFormContainerInner {
        height: 150px;
      }
      .loginDialogFormContainerInner {
        height: 205px;
        margin-bottom: 15px;
      }
      .forgotForm {
        height: 150px;
      }
      .loginForm {
        height: 200px;
      }
      .accountLink {
        font-size: 14px;
        margin-bottom: 0;
        &.forgotLink {
          margin-bottom: 12px;
        }
        .accountLinkButton {
          cursor: pointer;
        }
      }
    }
    .orDivider {
      color: $grey-accent;
      font-size: 16px;
      margin-top: 0;
      &.signupOrDivider {
        margin-top: 10px;
        margin-bottom: 3px;
      }
    }
    .facebookAuth {
      margin: 7px auto;
    }
  }
}
